import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "teams"
    }}>{`Teams`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchteams"
    }}>{`fetchTeams`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <p>{`No specific params.`}</p>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* teams -> Team[] = an array of teams
* count -> number = number of teams matching
* nextPageToken -> string = page token if there are matches beyond the limit
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchTeams } from '@kineticdata/react';

fetchTeams().then(({ teams }) => console.log(teams));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "fetchteam"
    }}>{`fetchTeam`}</h2>
    <h3 {...{
      "id": "params-1"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* teamSlug -> string = (required) slug of the team
`}</code></pre>
    <h3 {...{
      "id": "resolves-1"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* team -> Team{} = a team object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchTeam } from '@kineticdata/react';

fetchTeam({ teamSlug }).then(({ team }) => console.log(team));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "updateteam"
    }}>{`updateTeam`}</h2>
    <h3 {...{
      "id": "params-2"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* teamSlug -> string = (required) slug of the team
* team -> Team{} = (required) the updated team data to send
`}</code></pre>
    <h3 {...{
      "id": "resolves-2"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* team -> Team{} = the updated team object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-2"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateTeam } from '@kineticdata/react';

updateTeam({
  teamSlug,
  team: teamWithChanges,
}).then(({ team }) => console.log(team));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "createteam"
    }}>{`createTeam`}</h2>
    <h3 {...{
      "id": "params-3"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* team -> Team{} = (required) the team data to send
`}</code></pre>
    <h3 {...{
      "id": "resolves-3"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* team -> Team{} = the created team object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-3"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { createTeam } from '@kineticdata/react';

createTeam({ team }).then(({ team }) => console.log(team));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "deleteteam"
    }}>{`deleteTeam`}</h2>
    <h3 {...{
      "id": "params-4"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* teamSlug -> string = (required) slug of the team
`}</code></pre>
    <h3 {...{
      "id": "resolves-4"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* team -> Team{} = the deleted team object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-4"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { deleteTeam } from '@kineticdata/react';

deleteTeam({ teamSlug }).then(({ team }) => console.log(team));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      